import classes from './Clock.module.css';
import { useEffect, useState } from 'react';

const Clock = (props) => {
  const [currentTime, setCurrentTime] = useState(new Date());
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [currentTime]);

  return (
    <div className={classes.clock}>{currentTime.toLocaleTimeString()}</div>
  );
};

export default Clock;
