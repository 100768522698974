import React, { Suspense, useContext } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import HomePageContent from './components/HomePage/HomePageContent';
import Layout from './components/layouts/Layout';
import LoadingSpinner from './components/ui/LoadingSpinner';
import AuthContext from './store/auth-context';

const AboutPage = React.lazy(() => import('./pages/AboutPage'));
const PublicationPage = React.lazy(() => import('./pages/PublicationPage'));
const ResearchPage = React.lazy(() => import('./pages/ResearchPage'));
const TBAR = React.lazy(() => import('./components/ResearchPage/TBAR'));
const AuthPage = React.lazy(() => import('./pages/AuthPage'));
const CoursePage = React.lazy(() => import('./pages/CoursePage'));
const GetCourseDoc = React.lazy(() =>
  import('./components/CoursePage/GetCourseDoc')
);
const CourseItem = React.lazy(() =>
  import('./components/CoursePage/CourseItem')
);
const AdminAddCourseForm = React.lazy(() =>
  import('./components/Admin/AdminCourse/AdminAddCourseForm')
);
const AdminEditCourseForm = React.lazy(() =>
  import('./components/Admin/AdminCourse/AdminEditCourseForm')
);
const AdminDeleteCourseForm = React.lazy(() =>
  import('./components/Admin/AdminCourse/AdminDeleteCourseForm')
);
const AdminCoursePage = React.lazy(() => import('./pages/AdminCoursePage'));
const AdminCourseItem = React.lazy(() =>
  import('./components/Admin/AdminCourse/AdminCourseItem')
);
const AdminGetCourseDoc = React.lazy(() =>
  import('./components/Admin/AdminCourse/AdminGetCourseDoc')
);
const AdminAddDownloadForm = React.lazy(() =>
  import('./components/Admin/AdminCourse/AdminAddDownloadForm')
);
const AdminEditDownloadForm = React.lazy(() =>
  import('./components/Admin/AdminCourse/AdminEditDownloadForm')
);
const AdminDeleteDownloadForm = React.lazy(() =>
  import('./components/Admin/AdminCourse/AdminDeleteDownloadForm')
);
const AdminAddVideoForm = React.lazy(() =>
  import('./components/Admin/AdminCourse/AdminAddVideoForm')
);
const AdminEditVideoForm = React.lazy(() =>
  import('./components/Admin/AdminCourse/AdminEditVideoForm')
);
const AdminDeleteVideoForm = React.lazy(() =>
  import('./components/Admin/AdminCourse/AdminDeleteVideoForm')
);
const AdminUserPage = React.lazy(() => import('./pages/AdminUserPage'));

const App = () => {
  const authCtx = useContext(AuthContext);
  const { isAdmin, token } = authCtx;

  let routes;

  if (token) {
    routes = (
      <Switch>
        <Route path='/' exact>
          <HomePageContent />
        </Route>
        <Route path='/course' exact>
          <CoursePage />
        </Route>
        <Route path='/course/:courseCode' exact>
          <CourseItem />
        </Route>
        <Route path='/course/:courseCode/:courseDoc' exact>
          <GetCourseDoc />
        </Route>
        <Route path='/admin/user' exact>
          {isAdmin && <AdminUserPage />}
          {!isAdmin && <Redirect to='/' />}
        </Route>
        <Route path='/admin/course' exact>
          {isAdmin && <AdminCoursePage />}
          {!isAdmin && <Redirect to='/course' />}
        </Route>
        <Route path='/admin/course/add' exact>
          {isAdmin && <AdminAddCourseForm />}
          {!isAdmin && <Redirect to='/course' />}
        </Route>
        <Route path='/admin/course/:courseCode/download' exact>
          {isAdmin && <AdminCourseItem type='download' />}
          {!isAdmin && <Redirect to='/course' />}
        </Route>
        <Route path='/admin/course/:courseCode/video' exact>
          {isAdmin && <AdminCourseItem type='video' />}
          {!isAdmin && <Redirect to='/course' />}
        </Route>
        <Route path='/admin/course/:courseCode/edit' exact>
          {isAdmin && <AdminEditCourseForm />}
          {!isAdmin && <Redirect to='/course/:courseCode' />}
        </Route>
        <Route path='/admin/course/:courseCode/delete' exact>
          {isAdmin && <AdminDeleteCourseForm />}
          {!isAdmin && <Redirect to='/course/:courseCode' />}
        </Route>
        <Route path='/admin/course/:courseCode/:courseDoc' exact>
          {isAdmin && <AdminGetCourseDoc />}
          {!isAdmin && <Redirect to='/course/:courseCode' />}
        </Route>
        <Route path='/admin/course/:courseCode/download/add' exact>
          {isAdmin && <AdminAddDownloadForm />}
          {!isAdmin && <Redirect to='/course/:courseCode' />}
        </Route>
        <Route path='/admin/course/:courseCode/download/:downloadId/edit' exact>
          {isAdmin && <AdminEditDownloadForm />}
          {!isAdmin && <Redirect to='/course/:courseCode' />}
        </Route>
        <Route
          path='/admin/course/:courseCode/download/:downloadId/delete'
          exact
        >
          {isAdmin && <AdminDeleteDownloadForm />}
          {!isAdmin && <Redirect to='/course/:courseCode' />}
        </Route>
        <Route path='/admin/course/:courseCode/video/add' exact>
          {isAdmin && <AdminAddVideoForm />}
          {!isAdmin && <Redirect to='/course/:courseCode' />}
        </Route>
        <Route path='/admin/course/:courseCode/video/:videoId/edit' exact>
          {isAdmin && <AdminEditVideoForm />}
          {!isAdmin && <Redirect to='/course/:courseCode' />}
        </Route>
        <Route path='/admin/course/:courseCode/video/:videoId/delete' exact>
          {isAdmin && <AdminDeleteVideoForm />}
          {!isAdmin && <Redirect to='/course/:courseCode' />}
        </Route>

        <Route path='*'>
          <Redirect to='/' />
        </Route>
      </Switch>
    );
  } else {
    routes = (
      <Switch>
        <Route path='/' exact>
          <HomePageContent />
        </Route>
        <Route path='/auth/signup'>
          <AuthPage type={'signup'} />
        </Route>
        <Route path='/auth/login'>
          <AuthPage type={'login'} />
        </Route>
        <Route path='/auth/reset' exact>
          <AuthPage type={'reset'} />
        </Route>
        <Route path='/auth/reset/:token'>
          <AuthPage type={'reset'} />
        </Route>
        <Route path='/about'>
          <AboutPage />
        </Route>
        <Route path='/pub'>
          <PublicationPage />
        </Route>
        <Route path='/research' exact>
          <ResearchPage />
        </Route>
        <Route path='/research/tbar' exact>
          <TBAR />
        </Route>
        <Route path='*'>
          <Redirect to='/' />
        </Route>
      </Switch>
    );
  }

  return (
    <Layout>
      <Suspense
        fallback={
          <div className={'centered'}>
            <LoadingSpinner />
          </div>
        }
      >
        {routes}
      </Suspense>
    </Layout>
  );
};

export default App;
