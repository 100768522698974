import classes from './HomePageContent.module.css';
import { Link, useHistory } from 'react-router-dom';

import { useContext } from 'react';
import AuthContext from '../../store/auth-context';
import Button from '../ui/Button';

const HomePageContent = () => {
  const authCtx = useContext(AuthContext);
  const history = useHistory();
  const { username } = authCtx;
  const isLoggedIn = authCtx.isLoggedIn;

  const onMyCourseHandler = () => {
    history.replace('/course');
  };

  return (
    <div className={classes['main-content']}>
      <h1>{`Welcome${username ? `, ${username}` : ''}!`}</h1>
      <ul>
        {!isLoggedIn && (
          <li>
            <Link className={classes.btn} to='/auth/login'>
              Log In
            </Link>
          </li>
        )}
        {!isLoggedIn && (
          <li>
            <Link className={classes.btn} to='/auth/signup'>
              Sign Up
            </Link>
          </li>
        )}
      </ul>
      {isLoggedIn && <Button onClick={onMyCourseHandler}>My Courses</Button>}
    </div>
  );
};

export default HomePageContent;
