import classes from './MainNav.module.css';

const Footer = () => {
  return (
    <div className={classes.footer}>
      <p>
        © 2017- {new Date().getFullYear()} Mingxia Zhu - All Rights Reserved.
      </p>
      <p>Designed by Chao Xu</p>{' '}
    </div>
  );
};

export default Footer;
