import { useContext, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import AuthContext from '../../store/auth-context';

import Clock from '../ui/Clock';
import { Backdrop } from '../ui/ErrorModal';

import classes from './MainNav.module.css';
import { FaListUl } from 'react-icons/fa';

const MainNav = () => {
  const authCtx = useContext(AuthContext);

  const { isLoggedIn, isAdmin, username } = authCtx;
  const [showNav, setShowNav] = useState(false);

  const onLogoutHandler = () => {
    setShowNav(false);
    authCtx.logout();
  };

  const onLinkClickHandler = () => {
    setShowNav(false);
  };

  const onToggleHandler = () => {
    setShowNav((prev) => !prev);
  };

  return (
    <header className={classes.header}>
      <div className={classes.logo}>
        <Link to='/'>Prof. Mingxia Zhu</Link>
      </div>
      <nav>
        <FaListUl
          className={`${classes.header__menuicon} ${
            showNav ? classes.active : ''
          }`}
          onClick={onToggleHandler}
        />
        {showNav && <Backdrop onConfirm={onLinkClickHandler}></Backdrop>}
        <div
          className={classes.header__links}
          id={showNav ? classes.hidden : ''}
        >
          {!isLoggedIn && (
            <NavLink
              activeClassName={classes.active}
              onClick={onLinkClickHandler}
              to='/about'
            >
              About Me
            </NavLink>
          )}
          {!isLoggedIn && (
            <NavLink
              activeClassName={classes.active}
              onClick={onLinkClickHandler}
              to='/pub'
            >
              Publication
            </NavLink>
          )}
          {!isLoggedIn && (
            <NavLink
              activeClassName={classes.active}
              onClick={onLinkClickHandler}
              to='/research'
            >
              Database
            </NavLink>
          )}
          {isLoggedIn && (
            <NavLink
              activeClassName={classes.active}
              onClick={onLinkClickHandler}
              to='/course'
            >
              Course
            </NavLink>
          )}
          {isAdmin && isLoggedIn && (
            <NavLink
              activeClassName={classes.active}
              onClick={onLinkClickHandler}
              to='/admin/course'
            >
              Admin Course
            </NavLink>
          )}
          {isAdmin && isLoggedIn && (
            <NavLink
              activeClassName={classes.active}
              onClick={onLinkClickHandler}
              to='/admin/user'
            >
              Admin User
            </NavLink>
          )}
          {!isLoggedIn && (
            <div className={classes['btn--container']}>
              <Link
                className={classes.btn}
                onClick={onLinkClickHandler}
                to='/auth/login'
              >
                Log In
              </Link>

              <Link
                className={classes.btn}
                onClick={onLinkClickHandler}
                to='/auth/signup'
              >
                Sign Up
              </Link>
            </div>
          )}
          {isLoggedIn && (
            <div className={classes['nav--clock']}>
              <p>{`Welcome, ${username}!`}</p>
              <Clock />
            </div>
          )}
          {isLoggedIn && (
            <Link className={classes.btn} onClick={onLogoutHandler} to='/'>
              Logout
            </Link>
          )}
        </div>
      </nav>
    </header>
  );
};

export default MainNav;
